// components/OrdersList.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './OrdersList.css';
import { BACKEND_URL } from './config_url'; 

const OrdersList = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const urldapi = BACKEND_URL; // ou o URL do servidor

  const [idusuario, setIdusuario] = useState('');

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        setIdusuario(user.id);
      }
    } catch (error) {
      console.error('Erro ao obter dados do usuário:', error);
    }
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const urlpedido = `${urldapi}/api/pedidos/usuario/${idusuario}`
        const response = await fetch(urlpedido);
        if (!response.ok) {
          throw new Error('Erro ao buscar os pedidos.');
        }
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        setError('Erro ao carregar os pedidos.');
        console.error('Error fetching orders:', error);
      }
    };

    if (idusuario) {
      fetchOrders();
    }
  }, [idusuario]);

  return (
    <div className="orders-list-container">
      <h1>Meus Pedidos</h1>
      {error && <p className="error-message">{error}</p>}
      {orders.length === 0 ? (
        <p>Você ainda não fez nenhum pedido.</p>
      ) : (
        <table className="orders-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Produto</th>
              <th>Quantidade</th>
              <th>Valor Total</th>
              <th>Status</th>
              <th>Data</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{order.produto_id}</td>
                <td>{order.quantidade_comprada}</td>
                <td>R${parseFloat(order.valor).toFixed(2)}</td>
                <td>{order.status}</td>
                <td>{new Date(order.createdAt).toLocaleDateString('pt-BR')}</td>
                <td>
                  <Link to={`/numeros/${order.produto_id}/${idusuario}/${order.id_compra}`}>Ver Detalhes</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default OrdersList;
