// front/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Home from './components/Home';
import CadastroProduto from './components/CadastroProduto';
import OrdersList from './components/OrdersList';
import ProductDetail from './components/ProductDetail';
import NumbersList from './components/NumbersList';
import RandomNumberPicker from './components/RandomNumberPicker';
import NumbersListAdmin from './components/NumbersListAdmin';
import ProtectedRoute from './components/ProtectedRoute'; // Importa o ProtectedRoute
import './App.css'; // Importa o CSS
import Header from './components/Header'; // Importa o Header
import Footer from './components/Footer';

function App() {
  console.log('App Component Rendered');

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Login />} />
        
        <Route
          path="/home"
          element={
            <ProtectedRoute>
               <Header />
              <Home />
              <Footer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cadastrarproduto"
          element={
            <ProtectedRoute> 
              <Header />
              <CadastroProduto />
              <Footer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/product/:id"
          element={
            <ProtectedRoute>
               <Header />
              <ProductDetail />
              <Footer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orderlist/:id"
          element={
            <ProtectedRoute>
               <Header />
              <OrdersList />
              <Footer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/numeros/:produtoId/:usuarioId/:id_compra"
          element={
            <ProtectedRoute>
               <Header />
              <NumbersList />
              <Footer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/numeros/:produtoId"
          element={
            <ProtectedRoute>
               <Header />
              <NumbersListAdmin />
              <Footer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sorteio/:produtoId"
          element={
            <ProtectedRoute>
               <Header />
              <RandomNumberPicker />
              <Footer />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
