import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';

const CadastroProduto = () => {
  const [thumbnail, setThumbnail] = useState(null); // Atualizado para ser um arquivo
  const [nome_produto, setNome] = useState('');
  const [valor_unitario, setValorUnitario] = useState('');
  const [data_inicio_venda, setInicioVenda] = useState('');
  const [data_limite_sorteio, setLimiteSorteio] = useState('');
  const [maxnumeros, setMaxNumeros] = useState('');
  const [descricao, setDescricao] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    const produto = await authService.cadastroprodutos(thumbnail, nome_produto, valor_unitario, data_inicio_venda, data_limite_sorteio, maxnumeros, descricao);
    if (produto) {
      navigate('/home');
    } else {
      alert('Cadastro falhou');
    }
  };

  return (
    <div className="register-container">
      <div className="register-form">
        <h2>Cadastro de Produto</h2>
        <form onSubmit={handleRegister}>
          <input
            type="file"
            onChange={(e) => setThumbnail(e.target.files[0])} // Atualizado para arquivos
            required
          />
          <input
            type="text"
            value={nome_produto}
            onChange={(e) => setNome(e.target.value)}
            placeholder="Nome do Produto"
            required
          />
          <input
            type="text"
            value={valor_unitario}
            onChange={(e) => setValorUnitario(e.target.value)}
            placeholder="Valor da Unidade Ex: 1.50 utilize ponto"
            required
          />
          Data Inicio das Vendas
          <input
            type="date"
            value={data_inicio_venda}
            onChange={(e) => setInicioVenda(e.target.value)}
            placeholder="Data Inicio Das Vendas"
            required
          />
          Data Limite Sorteio
          <input
            type="date"
            value={data_limite_sorteio}
            onChange={(e) => setLimiteSorteio(e.target.value)}
            placeholder="Data Sorteio"
            required
          />
          Máximo de Números
          <input
            type="number"
            value={maxnumeros}
            onChange={(e) => setMaxNumeros(e.target.value)}
            placeholder="Quantidade de Números"
            required
          />
          Descrição
          <textarea
            id="descricao"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            placeholder="Descrição"
            required
            rows="4"
            cols="50"
          />
          <button type="submit">Cadastrar</button>
        </form>
      </div>
    </div>
  );
};

export default CadastroProduto;
