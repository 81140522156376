import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './NumbersList.css';
import { BACKEND_URL } from './config_url';

const RandomNumberPicker = () => {
  const { produtoId } = useParams();
  const [numbers, setNumbers] = useState([]);
  const [randomNumber, setRandomNumber] = useState(null);
  const [user, setUser] = useState({});
  const [product, setProduct] = useState({});
  const [error, setError] = useState(null);
  const [marqueeText, setMarqueeText] = useState("");
  const [marqueeRunning, setMarqueeRunning] = useState(false);
  const urldapi = BACKEND_URL;

  useEffect(() => {
    const fetchNumbers = async () => {
      try {
        console.log(`Fetching numbers for product ID: ${produtoId}`);
        const response = await fetch(`${urldapi}/api/numeros/produto/${produtoId}/admin`);
        if (!response.ok) {
          throw new Error('Erro ao buscar os números.');
        }
        const data = await response.json();
        console.log('Numbers fetched:', data);
        setNumbers(data);

        const userIds = [...new Set(data.map(number => number.usuario_id))];
        userIds.forEach(id => fetchUser(id));
      } catch (error) {
        setError('Erro ao carregar os números.');
        console.error('Error fetching numbers:', error);
      }
    };

    const fetchUser = async (usuarioId) => {
      try {
        console.log(`Fetching user with ID: ${usuarioId}`);
        const response = await fetch(`${urldapi}/api/auth/consultausuario/${usuarioId}`);
        if (!response.ok) {
          throw new Error('Erro ao buscar o usuário.');
        }
        const datauser = await response.json();
        console.log(`User fetched for ID ${usuarioId}:`, datauser);
        setUser(prevUsers => ({ ...prevUsers, [usuarioId]: datauser }));
      } catch (error) {
        setError('Erro ao carregar usuário.');
        console.error('Error fetching user:', error);
      }
    };

    const fetchProducts = async (iddoproduto) => {
      try {
        console.log(`Fetching product with ID: ${iddoproduto}`);
        const response = await fetch(`${urldapi}/api/products/${iddoproduto}`);
        if (!response.ok) {
          throw new Error('Erro ao buscar o produto.');
        }
        const dataprodutos = await response.json();
        console.log(`Product fetched for ID ${iddoproduto}:`, dataprodutos);
        setProduct(dataprodutos);
      } catch (error) {
        setError('Erro ao carregar produto.');
        console.error('Error fetching produto:', error);
      }
    };

    if (produtoId) {
      fetchNumbers();
      fetchProducts(produtoId);
    }
  }, [produtoId, urldapi]);

  const getRandomNumber = () => {
    if (numbers.length === 0) return;

    setMarqueeRunning(true);
    setMarqueeText(numbers.map(number => number.numero_comprado).join(' '));
    let i = 0;

    const interval = setInterval(() => {
      setMarqueeText(prevText => {
        const textArray = prevText.split(' ');
        return textArray.concat(textArray.shift()).join(' ');
      });
      i++;
      if (i > 20) {
        clearInterval(interval);
        const index = Math.floor(Math.random() * numbers.length);
        const selectedNumber = numbers[index];
        console.log('Selected number:', selectedNumber);
        setRandomNumber(selectedNumber);
        setMarqueeRunning(false);
      }
    }, 50);
  };

  return (
    <div className="numbers-list-container">
      <h1>Sortear Número</h1>
      {error && <p className="error-message">{error}</p>}
      <button className="round-button" onClick={getRandomNumber}>Sortear</button>

      {marqueeText && marqueeRunning && (
        <div className="marquee">
          <div className={`marquee-content ${randomNumber ? 'marquee-stopped' : ''}`}>{marqueeText}</div>
        </div>
      )}

      {randomNumber && (
        <div>
          <h2>Número Sorteado: {randomNumber.numero_comprado}</h2>
          <p>ID do Número: {randomNumber.id}</p>
          <p>Valor de Compra: R${parseFloat(randomNumber.valor_de_compra).toFixed(2)}</p>
          <p>Nome do Produto: {product.nome_produto || 'Produto não encontrado'}</p>
          <p>Nome do Comprador: {user[randomNumber.usuario_id]?.nome_completo || 'Usuário não encontrado'}</p>
        </div>
      )}
    </div>
  );
};

export default RandomNumberPicker;
