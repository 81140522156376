import React, { useEffect, useState } from 'react';
import productService from '../services/productService';
import inativarproduto from '../services/productService';
import './Home.css'; // Certifique-se de criar este arquivo CSS
import LogoutButton from './LogoutButton';

const Home = () => {
  const [products, setProducts] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Carrega o usuário do localStorage
    const storedUser = JSON.parse(localStorage.getItem('user'));
    setUser(storedUser);

    const fetchProducts = async () => {
      const data = await productService.getProducts();
      setProducts(data);
    };
    fetchProducts();
  }, []);

  // Função para inativar produto
  const handleInactivate = async (productId) => {
    try {
      await productService.inativarproduto(productId); // Acessa a função através do objeto productService
      // Atualiza a lista de produtos após a inativação
      setProducts(prevProducts =>
        prevProducts.map(product =>
          product.id === productId ? { ...product, ativo: false } : product
        )
      );
    } catch (error) {
      console.error('Erro ao inativar produto:', error);
    }
  };

  // Função para ativar produto
  const handleactivate = async (productId) => {
    try {
      await productService.ativarproduto(productId); // Acessa a função através do objeto productService
      // Atualiza a lista de produtos após a inativação
      setProducts(prevProducts =>
        prevProducts.map(product =>
          product.id === productId ? { ...product, ativo: true } : product
        )
      );
    } catch (error) {
      console.error('Erro ao ativar produto:', error);
    }
  };

  return (
    <div className="home-container">
      <LogoutButton />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      {user && user.tipo_usuario === 'adminAnd83ndA2345' && (
        <div className="products-grid">

          {products.filter(product => product).map(product => (

            <div className="product-card" key={product.id}>
              <div>
                <button className="desativar" onClick={() => handleInactivate(product.id)}>❌Desativar🗑️</button><button className="ativar" onClick={() => handleactivate(product.id)}>🟢Ativar</button>
                <img src={product.thumbnail_url} alt={product.nome_produto} className="product-thumbnail" />
                <h3 className="product-name">{product.nome_produto}</h3>
                <h2 className="product-name">{product.ativo ? 'Ativo' : 'Inativo'}</h2>
                <p className="product-price">Inicio: {new Date(product.data_inicio_venda).toLocaleDateString('pt-BR')}</p>
                <p className="product-price">Sorteio: {new Date(product.data_limite_sorteio).toLocaleDateString('pt-BR')}</p>
                <p className="product-price">
                  {product.valor_unitario ? 'R$' + parseFloat(product.valor_unitario).toFixed(2) : 'Sem Valor Até o Momento.'}
                </p>
                <p className="product-price">
                  Máx. Números:{product.maxnumeros}
                </p>

                <a href={`/product/${product.id}`}>Ver Detalhes</a>
                <br />
                <div className="button-container">
                  <a className="numeros" href={`/numeros/${product.id}`}>Ver Números Comprados</a>
                  <a className="sorteio" href={`/sorteio/${product.id}`}>Sortear Número</a>
                </div>
              </div>

            </div>
          ))}

        </div>)}
        {user && user.tipo_usuario === 'usuario' && (
        <div className="products-grid">

          {products.filter(product => product.ativo === true).map(product => (

            <div className="product-card" key={product.id}>
              <div>
                <img src={product.thumbnail_url} alt={product.nome_produto} className="product-thumbnail" />
                <h3 className="product-name">{product.nome_produto}</h3>
        
                <p className="product-price">Inicio: {new Date(product.data_inicio_venda).toLocaleDateString('pt-BR')}</p>
                <p className="product-price">Sorteio: {new Date(product.data_limite_sorteio).toLocaleDateString('pt-BR')}</p>
                <p className="product-price">
                  {product.valor_unitario ? 'R$' + parseFloat(product.valor_unitario).toFixed(2) : 'Sem Valor Até o Momento.'}
                </p>
                <p className="product-price">
                  Máx. Números:{product.maxnumeros}
                </p>

                <a href={`/product/${product.id}`}>Ver Detalhes</a>
                <br />
              </div>

            </div>
          ))}

        </div>)}
    </div>
  );
};

export default Home;
