// components/ProductDetail.js
import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import './ProductDetail.css';
import { useParams } from 'react-router-dom';
import productService from '../services/productService';
import { BACKEND_URL } from './config_url';

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState(null);
  const [pixCopiaECola, setPixCopiaECola] = useState('');
  const [pedidoId, setPedidoId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); 

  const urldapi = BACKEND_URL;

  // Recupera dados do usuário do localStorage dentro do componente
  const [nomecompleto, setNomecompleto] = useState('');
  const [cpf, setCpf] = useState('');
  const [idusuario, setIdusuario] = useState('');

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        setNomecompleto(user.nome_completo);
        setCpf(user.cpf);
        setIdusuario(user.id);
      }
    } catch {
      setNomecompleto('a');
      setCpf('b');
      setIdusuario('c');
    }
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const data = await productService.getProductById(id);
        setProduct(data);
      } catch (error) {
        setError('Erro ao buscar detalhes do produto.');
        console.error('Error fetching product details:', error);
      }
    };

    fetchProduct();
  }, [id]);

  const handleQuantityChange = (e) => {
    setQuantity(Number(e.target.value));
  };

  const handlePurchase = async () => {
    try {
      // Criação do pedido
      const pedidoResponse = await fetch(`${urldapi}/api/pedidos`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          usuario_id: idusuario,
          produto_id: id,
          quantidade_comprada: quantity,
          valor: (product.valor_unitario * quantity).toFixed(2),
          id_compra: 1, 
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        }),
      });

      if (!pedidoResponse.ok) {
        const errorData = await pedidoResponse.json();
        throw new Error(errorData.message || 'Erro ao atualizar o pedido');
      }

      const pedidoData = await pedidoResponse.json();
      console.log('Pedido criado:', pedidoData);
      setPedidoId(pedidoData.id);

      // Geração da cobrança
      const billingResponse = await fetch(`${urldapi}/gerar-cobranca`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          valor: (product.valor_unitario * quantity).toFixed(2),
          cpf: cpf,
          nome: nomecompleto,
          pedidoId: pedidoData.id
        }),
      });

      if (!billingResponse.ok) {
        throw new Error('Erro ao criar a cobrança');
      }

      const billingData = await billingResponse.json();
      console.log('Cobrança gerada:', billingData);

      setPixCopiaECola(billingData.qrCodeData.pixCopiaECola);
      setIsModalOpen(true); 
      alert('Compra realizada com sucesso!');
    } catch (errorData) {
      setError(errorData.message);
      console.error('Error purchasing product:', errorData);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(pixCopiaECola)
      .then(() => {
        alert('Código copiado para a área de transferência!');
      })
      .catch((error) => {
        console.error('Erro ao copiar o código:', error);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!product) {
    return <p>Carregando...</p>;
  }

  return (
    <div className="product-detail-container">
      <h1>{product.nome_produto}</h1>
      <img src={product.thumbnail_url} alt={product.nome_produto} className="product-detail-thumbnail" />
      <p><strong>Início da Venda:</strong> {new Date(product.data_inicio_venda).toLocaleDateString('pt-BR')}</p>
      <p><strong>Data do Sorteio:</strong> {new Date(product.data_limite_sorteio).toLocaleDateString('pt-BR')}</p>
      <p><strong>Valor Unitário:</strong> {product.valor_unitario ? 'R$' + parseFloat(product.valor_unitario).toFixed(2) : 'Sem Valor Até o Momento.'}</p>
      <p><strong>Maximo de Números:</strong> {product.maxnumeros}</p>
      <p><strong>Descrição:</strong><br />{product.descricao}</p>

      <div className="purchase-section">
        <label htmlFor="quantity">Quantidade de Números:</label>
        <input
          type="number"
          id="quantity"
          value={quantity}
          min="1"
          onChange={handleQuantityChange}
          className="quantity-input"
        />
        <button onClick={handlePurchase} className="purchase-button">Comprar Números</button> 
        <div>
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="modal-close" onClick={closeModal}>×</button>
            <h2>QR Code para Pagamento:</h2>
            <QRCode value={pixCopiaECola} size={256} />
            <p><strong>Este QRCode é válido apenas por 10 minutos...</strong></p>
            
            <div className="pix-code-section">
              <p className="pix-code-text">{pixCopiaECola}</p>
              <br></br>
              <button onClick={handleCopy} className="copy-button">Copiar Código</button><br></br>
              <p className="aviso" >Após o pagamento, acesse:<br></br><strong>Meus Pedidos</strong> e clique em <strong>Ver Detalhes</strong> para conferir seus números.</p>
  
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
