
//front services/productService.js
import { BACKEND_URL } from '../components/config_url'; 
const API_URL = `${BACKEND_URL}/api/products/`;//localhost

const getProducts = async () => {
  const response = await fetch(API_URL);
  return response.ok ? await response.json() : [];
};

const productService = {
  getProducts
};
const getProductById = async (id) => {
  const response = await fetch(`${API_URL}${id}`);
  //console.log(response)
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};
// Adiciona função para compra
const purchaseProduct = async (productId, quantity) => {
  const response = await fetch(`${API_URL}purchase`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ productId, quantity }),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

// Adiciona função desativar  produto
const inativarproduto = async (productId) => {
  try {
    const response = await fetch(`${API_URL}${productId}`, {
      method: 'PUT', // Usar PATCH ou PUT para atualização parcial
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ativo: false }), // Inativa o produto
    });

    if (!response.ok) {
      throw new Error(`Erro de rede: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao inativar o produto:', error);
    throw error; // Propagar o erro para ser tratado na UI
  }
};
// Adiciona função ativar produto
const ativarproduto = async (productId) => {
  try {
    const response = await fetch(`${API_URL}${productId}`, {
      method: 'PUT', // Usar PATCH ou PUT para atualização parcial
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ativo: true }), // Inativa o produto
    });

    if (!response.ok) {
      throw new Error(`Erro de rede: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao inativar o produto:', error);
    throw error; // Propagar o erro para ser tratado na UI
  }
};

export default {
  getProducts,
  getProductById,
  purchaseProduct,
  inativarproduto,
ativarproduto,
};