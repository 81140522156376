// components/NumbersList.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Importa o useParams
import './NumbersList.css';
import { BACKEND_URL } from './config_url'; 

const PAGE_SIZE = 5;

const NumbersList = () => {
  const { produtoId, usuarioId, id_compra } = useParams(); // Obtém os parâmetros da URL
  const [numbers, setNumbers] = useState([]);
  const [filteredNumbers, setFilteredNumbers] = useState([]);
  const [products, setProducts] = useState({});
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const urldapi = BACKEND_URL; // ou o URL do servidor

  useEffect(() => {
    const fetchNumbers = async () => {
      try {
        const response = await fetch(`${urldapi}/api/numeros/produto/${produtoId}/usuario/${usuarioId}/compra/${id_compra}`);
        if (!response.ok) {
          throw new Error('Erro ao buscar os números.');
        }
        const data = await response.json();
        setNumbers(data);
        setFilteredNumbers(data);

        // Fetch products for each unique product ID found in numbers
        const productIds = [...new Set(data.map(number => number.produto_id))];
        productIds.forEach(id => fetchProduct(id));
      } catch (error) {
        setError('Erro ao carregar os números.');
        console.error('Error fetching numbers:', error);
      }
    };

    const fetchProduct = async (productId) => {
      try {
        const response = await fetch(`${urldapi}/api/products/${productId}`);
        if (!response.ok) {
          throw new Error('Erro ao buscar o produto.');
        }
        const productData = await response.json();
        setProducts(prevProducts => ({ ...prevProducts, [productId]: productData }));
      } catch (error) {
        setError('Erro ao carregar produto.');
        console.error('Error fetching product:', error);
      }
    };

    if (produtoId && usuarioId) {
      fetchNumbers();
    }
  }, [produtoId, usuarioId, id_compra, urldapi]);

  useEffect(() => {
    // Filtra os números com base na consulta de pesquisa
    setFilteredNumbers(
      numbers.filter(number =>
        String(number.numero_comprado).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );

    // Redefine a página atual para 1 quando a pesquisa mudar
    setCurrentPage(1);
  }, [searchQuery, numbers]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastNumber = currentPage * PAGE_SIZE;
  const indexOfFirstNumber = indexOfLastNumber - PAGE_SIZE;
  const currentNumbers = filteredNumbers.slice(indexOfFirstNumber, indexOfLastNumber);

  // Calcula a soma da compra dos números na página atual
  const sumOfPage = currentNumbers.reduce((total, number) => total + parseFloat(number.valor_de_compra), 0);
  // Calcula a soma total
  const sumTotal = filteredNumbers.reduce((total, number) => total + parseFloat(number.valor_de_compra), 0);

  return (
    <div className="numbers-list-container">
      <h1>Meus Números</h1>
      {error && <p className="error-message">{error}</p>}
      <input
        type="text"
        placeholder="Buscar números..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      {numbers.length === 0 ? (
        <p>Você ainda não tem números para este produto.</p>
      ) : (
        <>
          <table className="numbers-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Número Comprado</th>
                <th>
                  Valor de Compra<br />
                  Página: R${sumOfPage.toFixed(2)} | Total: R${sumTotal.toFixed(2)}
                </th>
                <th>Nome do Produto</th>
              </tr>
            </thead>
            <tbody>
              {currentNumbers.map((number) => (
                <tr key={number.id}>
                  <td>{number.id}</td>
                  <td>{number.numero_comprado}</td>
                  <td>R${parseFloat(number.valor_de_compra).toFixed(2)}</td>
                  <td>{products[number.produto_id]?.nome_produto || 'Produto não encontrado'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: Math.ceil(filteredNumbers.length / PAGE_SIZE) }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={index + 1 === currentPage ? 'active' : ''}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default NumbersList;
