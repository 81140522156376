// src/config.js
//produção
const BACKEND_URL = 'https://backendrifa.pulsechat.com.br'; // URL do backend
const FRONTEND_URL = 'https://rifaonline.pulsechat.com.br'; // URL do frontend


//localhost
//const BACKEND_URL = 'http://localhost:8080'; // URL do backend
//const FRONTEND_URL = 'http://localhost:3000'; // URL do frontend

export { BACKEND_URL, FRONTEND_URL };
