//front components/Register.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import './Register.css'; // Importa o CSS

const Register = () => {
  console.log('Register Component Rendered');

  const [email, setEmail] = useState('');
  const [senha, setPassword] = useState('');
  const [usuario, setUsuario] = useState('');
  const [cpf, setCPF] = useState('');
  const [nome_completo, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    const user = await authService.register(email, usuario,cpf, nome_completo,senha,'usuario',telefone);
    if (user) {
      navigate('/login');
    } else {
      alert('Registration failed');
    }
  };

  return (
    <div className="register-container">
      <div className="register-form">
        <h2>Registro</h2>
        <form onSubmit={handleRegister}>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="Email" 
            required 
          />
          <input 
            type="text" 
            value={usuario} 
            onChange={(e) => setUsuario(e.target.value)} 
            placeholder="Usuario" 
            required 
          />
          <input 
            type="text" 
            value={nome_completo} 
            onChange={(e) => setNome(e.target.value)} 
            placeholder="Nome Completo" 
            required 
          />
          <input 
            type="text" 
            value={cpf} 
            onChange={(e) => setCPF(e.target.value)} 
            placeholder="CPF" 
            required 
          />
          <input 
            type="text" 
            value={telefone} 
            onChange={(e) => setTelefone(e.target.value)} 
            placeholder="Telefone (11)98765321" 
            required 
          />
          <input 
            type="password" 
            value={senha} 
            onChange={(e) => setPassword(e.target.value)} 
            placeholder="Password" 
            required 
          />
          <button type="submit">Register</button>
        </form>
        <p>Já tem uma conta? <a href="/login">Faça o Login</a></p>
      </div>
    </div>
  );
};

export default Register;
