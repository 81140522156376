// front/components/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null para indicar carregamento
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = () => {
      const sessionExpiration = localStorage.getItem('sessionExpiration');
      const currentTime = new Date().getTime();

      if (!sessionExpiration || currentTime > parseInt(sessionExpiration)) {
        // Sessão expirou ou não existe
        localStorage.removeItem('user');
        localStorage.removeItem('sessionExpiration');
        setIsAuthenticated(false);
        navigate('/login'); // Redireciona para a página de login
      } else {
        setIsAuthenticated(true);
      }
    };

    checkSession();
    // Verificar periodicamente se a sessão ainda está ativa
    const interval = setInterval(checkSession, 1000); // Checa a cada segundo

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, [navigate]);

  if (isAuthenticated === null) {
    return <div>Carregando...</div>; // Ou um carregando enquanto redireciona
  }

  if (!isAuthenticated) {
    return null; // Ou um carregando enquanto redireciona
  }

  return <>{children}</>;
};

export default ProtectedRoute;
