// front/services/authService.js
import { BACKEND_URL } from '../components/config_url'; 
const API_URL = `${BACKEND_URL}/api/auth/`;//local

const login = async (email, senha) => {
  const response = await fetch(`${API_URL}login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, senha }),
  });
  console.log(response)
  if (response.ok) {
    const data = await response.json();
    return data; // Supondo que data contenha { token, usuario }
  } else {
    throw new Error('Failed to login');
  }
};

const register = async (email,usuario,cpf,nome_completo,senha, tipo_usuario,telefone) => {
  const response = await fetch(`${API_URL}register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email,usuario,cpf,nome_completo,senha, tipo_usuario,telefone })
  });
  return response.ok;
};
const cadastroprodutos = async (thumbnail, nome_produto, valor_unitario, data_inicio_venda, data_limite_sorteio, maxnumeros, descricao,) => {
  // Cria uma instância do FormData
  const formData = new FormData();
  formData.append('thumbnail', thumbnail); // Adiciona o arquivo
  formData.append('nome_produto', nome_produto);
  formData.append('valor_unitario', valor_unitario);
  formData.append('data_inicio_venda', data_inicio_venda);
  formData.append('data_limite_sorteio', data_limite_sorteio);
  formData.append('maxnumeros', maxnumeros);
  formData.append('descricao', descricao);
  formData.append('caminho', BACKEND_URL);
  
  // Envia a requisição
  const response = await fetch(`${API_URL}cadastrarproduto`, {
    method: 'POST',
    body: formData, // Usa o FormData como corpo da requisição
  });

  return response.ok;
};
const authService = {
  register,
  login,
  cadastroprodutos
};

export default authService;
