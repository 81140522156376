import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css'; // Importa o CSS
import SessionTimer from './SessionTimer';

const Header = () => {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('user'));

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('sessionExpiration');
    window.location.href = '/login'; // Redireciona para a página de login
  };

  return (
    <header className="header">
      <div className="header-content">
        <div className="logo">
          <h1>RifaOnlinePro</h1>
        </div>
        <div className="user-info">
          {user && (
            <div className="user-details">
              <p className="user-name">Olá, {user.nome_completo}</p>
              <p className="user-email">Dados do Usuário: Email: {user.email} CPF: {user.cpf} Nome de Usuário: @{user.usuario}</p>
            </div>
          )}
        </div>
        <div className="menu-toggle" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <nav className={`nav ${menuOpen ? 'open' : ''}`}>
          <ul>
            <li>
              <Link to="/home" className={location.pathname === '/home' ? 'active' : ''}>Home</Link>
            </li>
            {user.tipo_usuario === 'adminAnd83ndA2345' && (
              <li>
                <Link to="/cadastrarproduto" className={location.pathname === '/cadastrarproduto' ? 'active' : ''}>Cadastrar Produto</Link>
              </li>
            )}
            <li>
              <Link to={`/orderlist/${user.id}`} className={location.pathname === `/orderlist/${user.id}` ? 'active' : ''}>Meus Pedidos</Link>
            </li>
            <li>
              <button onClick={handleLogout} className="logout-btn">Logout</button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
