// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* front/components/Footer.css */
.footer {
  background-color: #34495e;
  height: 30px;
  color: #00c2f3;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1000;
  color: #00c2f3;
  margin: 0; /* Remova qualquer margem se presente */
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;EACE,yBAAyB;EACzB,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yCAAyC;EACzC,eAAe;EACf,WAAW;EACX,SAAS;EACT,OAAO;EACP,aAAa;EACb,cAAc;EACd,SAAS,EAAE,uCAAuC;AACpD","sourcesContent":["/* front/components/Footer.css */\r\n.footer {\r\n  background-color: #34495e;\r\n  height: 30px;\r\n  color: #00c2f3;\r\n  padding: 10px 10px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);\r\n  position: fixed;\r\n  width: 100%;\r\n  bottom: 0;\r\n  left: 0;\r\n  z-index: 1000;\r\n  color: #00c2f3;\r\n  margin: 0; /* Remova qualquer margem se presente */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
