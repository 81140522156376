import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import './Login.css'; // Importa o CSS

const Login = () => {
  console.log('Login Component Rendered');

  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await authService.login(email, senha);
      
      console.log('Login Response:', response); // Exibe a resposta no console

      if (response) {
        const { id, usuario, email, cpf, nome_completo, tipo_usuario } = response;

        // Definir tempo de expiração (30 minutos)
        const expirationTime = new Date().getTime() + 30 * 60 * 1000; // 30 minutos em milissegundos

        // Armazenar informações do usuário e tempo de expiração no localStorage
        localStorage.setItem('user', JSON.stringify({
          id,
          usuario,
          email,
          cpf,
          nome_completo,
          tipo_usuario
        }));
        localStorage.setItem('sessionExpiration', expirationTime.toString());

        // Opcional: Armazenar um token se você estiver utilizando um
        // localStorage.setItem('authToken', 'seu_token_aqui');

        // Redirecionar para a página inicial ou para onde desejar
        navigate('/home');
      } else {
        setError('Login falhou. Verifique suas credenciais e tente novamente.');
      }
    } catch (error) {
      setError('Erro ao fazer login. Por favor, tente novamente.');
      console.error('Login error:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="Email" 
            required 
          />
          <input 
            type="password" 
            value={senha} 
            onChange={(e) => setSenha(e.target.value)} 
            placeholder="Password" 
            required 
          />
          <button type="submit">Login</button>
          {error && <p className="error-message">{error}</p>}
        </form>
        <p>Não tem uma conta? <a href="/register">Registre-se</a></p>
      </div>
    </div>
  );
};

export default Login;
