import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import './SessionTimer.css';


// Função para calcular o tempo restante
const calculateRemainingTime = (expirationTime) => {
  const now = Date.now();
  const remainingTime = expirationTime - now;
  return Math.max(remainingTime, 0); // Garantir que o tempo não seja negativo
};

const SessionTimer = () => {
  const [remainingTime, setRemainingTime] = useState(0);
  const navigate = useNavigate();
  
  useEffect(() => {
    const sessionExpiration = localStorage.getItem('sessionExpiration');
    
    if (sessionExpiration) {
      const expirationTime = parseInt(sessionExpiration, 10);
      setRemainingTime(calculateRemainingTime(expirationTime));
      
      const intervalId = setInterval(() => {
        const updatedRemainingTime = calculateRemainingTime(expirationTime);
        setRemainingTime(updatedRemainingTime);
        
        if (updatedRemainingTime <= 0) {
          clearInterval(intervalId);
          // Limpar o localStorage
          localStorage.removeItem('authToken');
          localStorage.removeItem('user');
          localStorage.removeItem('sessionExpiration');
          // Redirecionar para a página de login
          navigate('/login');
        }
      }, 1000);
      
      return () => clearInterval(intervalId);
    }
  }, [navigate]);
  
  const formatTime = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
    const hours = Math.floor(milliseconds / (1000 * 60 * 60)) % 24;
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };
  
  return (
    <div className="session-timer">
      <p>RifaOnlinePro 2024 Tempo Restante da Sessão: {formatTime(remainingTime)}  </p>
    </div>
  );
};

export default SessionTimer;
